import React from "react"
import Section from "../components/section"

import  "../pages/resume.css"
const RESUME_DATA = {
    title: 'Aaron Chu',
    subtitle: 'Writer and Researcher in Disability & Design, Software Engineer',
    contact: {
        tel: '646.912.3612',
        email: 'arona.chu [at] gmail.com',
    },
    data: [
        {
            title: 'Select Writings',
            data: [
                {
                    data: [
                        {
                            type: 'link',
                            title: 'Fast Company: \'Inclusive design\' Has Become So Widely Used That It\'s Meaningless. That Has To Change',
                            url: 'https://www.fastcompany.com/90697288/inclusive-design-has-become-so-widely-used-that-its-meaningless-that-has-to-change',
                        },
                        {
                            type: 'link',
                            title: 'Understanding Criticism Through Design',
                            url: 'https://includr.org/perspective/understanding-criticism-through-design/',
                        },
                        {
                            type: 'link',
                            title: 'Glass Ceiling in Tech: Has It Been Shattered for Women of Color Yet?',
                            url: 'https://observablehq.com/@aaronchu/glass-ceiling-in-tech',
                        },
                        {
                            type: 'pdf',
                            title: 'Opinion: MTA Accessibility as Top Priority?',
                            url: '../assets/writing-samples/Application-Opinion-MTA-Accessibility.pdf',
                        },
                        {
                            type: 'pdf',
                            title: 'The Ghosts of San Juan Hill',
                            url: '../assets/writing-samples/Application-San-Juan-Hill.pdf',
                        },
                    ]
                },
            ]
        },
        {
            title: 'Alt-text: A Podcast Recording',
            data: [
                {
                    data: [
                        {
                            type: 'audio',
                            title: 'Podcast (Unpublished)',
                            url: '../assets/writing-samples/CHU-AARON-ALT-TEXT-FINAL-CUT.mp3',
                        },
                        {
                            type: 'link',
                            title: 'Transcript',
                            url: 'https://docs.google.com/document/d/1VvxlmmYsZYhprZer68QvsGwZohq_30bdFwrqtBiP24g/',
                        }
                    ]
                }
            ]
        },
        // {
        //     title: 'SVA Thesis Presentation',
        //     data: [
        //         {
        //             data: [
        //                 { type: 'video' },
        //                 {
        //                     type: 'link',
        //                     title: 'Transcript',
        //                     url: 'https://docs.google.com/document/d/1eUoMHpW3k1F0qhB-iouTuhazvawt8FGAltgJnTTa1bU/',
        //                 }
        //             ]
        //         }
        //     ]
        // }
    ]
}

class WritingPortfolio extends React.Component {
  constructor(props) {
    super(props);

    const { title, subtitle, contact, data } = RESUME_DATA;
    this.title = title;
    this.subtitle = subtitle;
    this.contact = contact;

    this.children = data.map((content, idx) => {
        return (
            <Section key={`${content.title}-content-${idx}`} data={content} />
        )
    });
  }

  render() {
    const { title, subtitle, contact, children } = this;
    return (
        <div className="resume writing-portfolio">
            <h1>{title}</h1>
            <span className="resume-subtitle">{subtitle}</span>
            <div className="resume-content">
                {children}
            </div>
        </div>
    )
  }
}

export default WritingPortfolio