/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from 'react';

 const TYPES = ['link', 'pdf', 'audio', 'video'];

 const ContentBlock = ({ content }) => {
    const { data:list, school, title, location, position, degree, major, thesis, axTitle } = content;
    let isMobile;
    if (typeof window !== "undefined") {
        isMobile = false; // window?.matchMedia('(max-width: 480px)').matches;
    }
    return (
        <div>
            <h3>
                <span className="section-title" aria-label={axTitle} dangerouslySetInnerHTML={({ __html: title || school })}></span>
                {location && (<span className="section-sub section-sub-location">{location}</span>)}
                {position && (<span className="section-sub section-sub-position">{position}</span>)}
            </h3>
            {school && (
                <div className="school-meta">
                    <span className="section-sub section-sub-degree">{degree}</span>
                    <span className="section-sub section-sub-major">{major}</span>
                    <span className="section-sub section-sub-thesis">{thesis}</span>
                </div>
            )}
            {list?.length && (
                <ul>
                    {list.map((item, idx) => {
                        if (typeof item === 'string') {
                            return (
                                <li key={`item-${idx}`} dangerouslySetInnerHTML={{ __html: item }}></li>
                            )
                        }
                        if (TYPES.includes(item?.type)) {
                            switch(item.type) {
                                case 'link':
                                case 'pdf':
                                    return (
                                        <li key={`item-${idx}`}>
                                            <a href={item.url} target="blank" dangerouslySetInnerHTML={{ __html: item.title }}></a>
                                        </li>
                                    )
                                case 'video':
                                    return (
                                        <li key={`item-${idx}`}>
                                            {isMobile ? 
                                                (
                                                    <a href="https://vimeo.com/569159560">View on Vimeo</a>
                                                ) :
                                                (
                                                    <iframe src="https://player.vimeo.com/video/569159560?h=6da0e4fa8e" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                                )
                                            }
                                        </li>
                                    )
                                case 'audio':
                                    return (
                                        <li key={`item-${idx}`}>
                                            <audio controls>
                                                <source src={item.url} type="audio/mp3 " />
                                                Your browser does not support the audio tag.
                                            </audio>
                                        </li>
                                    )
                                default:
                                    break;
                            }
                        }
                        return (
                            <li key={`item-${idx}`}>
                                <span className={['list-title']}>{item.title}</span>
                                {item.data && (
                                    <ul>
                                        {item.data.map((text, idx) => {
                                            return (
                                                <li key={`item-sub-${idx}`}>{text}</li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    );
 }
 
 export default ContentBlock
 