/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from "react"
 import ContentBlock from "../components/content-block"

 import  "../components/section.css"

 class Section extends React.Component {
    constructor(props) {
        super(props);
        const { data } = props;
        const { title, data:entry } = data;
        const classNameTitle = (function(title) {
            if (title === 'Relevant Skills & Experience') return 'special-interests';
            if (title === 'Alt-text: A Podcast Recording') return 'recordings';
            if (title?.split(' ').length > 1) {
                return title.split(' ').join('-');
            }
            return title;
        })(title);
        const classNames = ['section', `section-${classNameTitle.toLowerCase()}`];

        let children = [];

        children = entry.map((group, idx) => {
            return (
                <ContentBlock key={`${title}-${idx}`} content={group} />
            )
        });

        this.children = children;
        this.classNames = classNames;
        this.title = title;
        this.timeout = null;
        this.state = {
            page: 1,
            totalPage: (function() {
                switch(title) {
                    case 'Experience':
                        return 3;
                    case 'Education':
                        return 2;
                    default:
                        return false;
                }
            }(title)),
        }
    }

    scrollHandler(event) {
        event.persist();
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({
                page: (event.target.scrollLeft % 3) + 1,
            })
        }, 100);
    }

    render() {
        return (
            <div className={ this.classNames.join(' ') }>
                <h2>{this.title} {this.state.totalPage && (
                    <span key="this.page" className="counter"> {this.state.page}  of {this.state.totalPage}</span>
                    )}
                </h2>
                <div className="section-content"
                     onScroll={this.scrollHandler.bind(this)}>
                    <span className='scroller'>
                        {this.children}
                    </span>
                </div>
            </div>
        ) 
    }

 }
 
 export default Section
 